<template>
    <div class="mt-16">
        <h1 class="text-center">
            {{ $t('general.login') }}
        </h1>

        <p class="mt-4 text-center text-sm text-gray-600">
            {{ $t('general.or') }}

            <nuxt-link class="font-medium link lowercase" to="/register">
                {{ $t('general.register') }}
            </nuxt-link>
        </p>

        <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md border rounded-xl">
            <div class="space-y-6 bg-white sm:rounded-xl py-8 px-4 sm:px-10">
                <forms-login-form/>

                <div class="text-center">
                  <nuxt-link to="/forgot/password" class="font-medium link text-sm">
                    {{ $t('page.login.forgot_password') }}
                  </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
definePageMeta({
    middleware: 'guest',
})

const {$global} = useNuxtApp()
const route = useRoute()
const router = useRouter()

onMounted(async () => {
    if (route.query.token) {
        const token = useCookie('token')
        token.value = route.query.token

        let query = Object.assign({}, route.query)
        delete query.token

        await router.replace({query})

        location.reload()
    }
})
</script>
