<template>
    <form class="space-y-6"
          @submit.prevent="login">
        <forms-inputs-input-email for="email" @set="form.email = $event.target.value"/>

        <forms-inputs-input-password for="password" @set="form.password = $event.target.value"/>

        <button class="button-primary w-full" type="submit"
                :class="{'opacity-50 cursor-not-allowed': disable_login_button}"
          :disabled="disable_login_button">
            {{ $t('general.login') }}
        </button>
    </form>
</template>

<script setup>
const form = reactive({
    email: '',
    password: '',
})

const {$global, $bus} = useNuxtApp()

const disable_login_button = ref(false)

function login() {
    disable_login_button.value = true

    $fetch(`${$global.api_url}/api/auth/login`, {
        method: 'POST',
        body: JSON.stringify(form),
    })
        .then(response => {
            const token = useCookie('token', {maxAge: 60 * 60 * 24 * 365})
            token.value = response.token

            location.reload()
        })
        .catch(error => {
            disable_login_button.value = false

            $bus.$emit('flash', {
                type: 'error',
                title: error.response._data.message
            })
        })
}
</script>
